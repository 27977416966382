@import '../../assets/scss/theme-variable.scss';

.auth-modal {
    .modal-dialog {
        width: 486px;
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;

        .modal-content {
            border-radius: 16px;
            background-color: #FFFBFF;
            border: none;
        }
    }

    .modal-header {
        border-bottom: none;
        padding: 24px 21px 0 24px;

        .modal-title {
            display: flex;
            justify-content: space-between;
            width: 100%;

            h1 {
                font-size: 32px;
                font-weight: 400;
                margin-bottom: 0;
            }

            .close-icon {
                cursor: pointer;
            }
        }
    }

    .modal-body {
        padding: 0;
    }

    .auth-modal-body-wrapper {
        padding: 24px;

        .main-title {
            font-size: 22px;
            font-weight: 400;
            text-align: center;
        }

        .text,
        .purple-text {
            color: #1E1B1E;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            margin-top: 24px;
        }

        .purple-text {
            color: #932DB1;
            margin-top: 2px;
            cursor: pointer;
        }

        .button-wrapper {
            margin-top: 24px;
            gap: 8px;

            .uiba-btn {
                background-color: $themeColor;
            }
        }

        &.sent-modal-body-wrapper,
        &.reset-modal-body-wrapper,
        &.consent-modal-body-wrapper {
            .main-title {
                font-size: 28px;
                font-weight: 500;
            }

            .text {
                font-weight: 400;
                font-size: 16px;
                text-align: center;
            }
        }

        &.consent-modal-body-wrapper {
            .main-title {
                margin-bottom: 24px;
            }

            .checkbox-wrapper {
                margin-bottom: 24px;

                .MuiFormControlLabel-label {
                    color: #1E1B1E;
                    font-weight: 400;
                    margin-left: 8px;
                    width: 100%;
                    letter-spacing: -0.5px !important;
                    line-height: 21px;
                }
            }

            .button-wrapper {
                margin-top: 10px;
            }
        }
    }

    &.cookies-modal {
        .modal-dialog {
            height: 25%;
            width: 38%;
            position: absolute;
            bottom: 32%;
            right: 10px;

            @media (max-width: 1164px) {
                bottom: 40%;
            }

            @media (max-width: 1024px) {
                bottom: 50%
            }

            @media (min-width: 1600px) {
                bottom: 25%;
            }

            @media (min-width: 1920px) {
                bottom: 10%
            }

            .modal-header {
                display: none;
            }

            .cookies-modal-body-wrapper {
                &__image-container {
                    display: flex;
                    margin-bottom: 30px;
                    justify-content: center;

                    &__icon {
                        width: 61px;
                        height: 61px;
                    }
                }

                &__title {
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 28px;
                }

                &__text {
                    font-size: 16px;
                    line-height: 24px;
                    text-align: center;
                    margin-top: 10px;
                    font-weight: 400;
                }

                &__privacy-link {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    text-align: center;
                    color: $themeColor;


                    span {
                        cursor: pointer;
                    }
                }

                .button-wrapper {
                    &__decline-btn {
                        border: 0;
                        background-color: transparent;
                        width: 131px;
                        height: 44px;
                        color: $themeColor;

                    }

                    &__accept-btn {
                        width: 131px;
                        height: 54px;
                        background-color: $themeColor;
                    }
                }
            }
        }
    }

    &.sent-modal {
        .sent-modal-body-wrapper {
            .otp {
                width: 50%;
                margin: auto;
                padding-top: 2.5%;
            }

            .countdown-text {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-top: 2.5%;
            }
        }
    }

    &.team-modal {
        .teams-title {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        .delete-info {
            display: flex;
            width: 100%;

            .team-name {
                text-decoration: underline;
                font-weight: 600;
                cursor: pointer;
                color: #1D8EE6;
                display: inline-block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 10ch;
            }
        }

        .delete-btn {
            margin-top: 2%;
            display: flex;
            width: 100%;
            justify-content: flex-end;
        }
    }

    &.admin-member-modal {
        .teams-title {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
    }
}