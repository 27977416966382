@import '../../assets/scss/theme-variable.scss';

.auth-page-section {
    height: 100vh;
    overflow: hidden;
    // max-width: 1920px;
    margin: auto auto !important;
    background-color: $bodyBackgroundColor;
    color: $textColor;

    .parent {
        position: relative;
    }

    .auth-left-side {
        width: 45%;
        background: #F5F7F9;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 100vh;
        position: relative;

        .main-logo {
            margin: auto;
            margin-bottom: 2.5vw;
            margin-top: 3.25vw;
            width: 81%;

            //    background-image: url(../../assets/images/logo.svg);
            //    background-size: contain;
            //    background-repeat: no-repeat;
            //    margin-bottom: 25px;
            //    margin-top: 40px;
            //    height: 10vh;
        }

        .main-banner {
            margin: auto;
            width: 81%;
            height: 44%;
            max-height: calc(100vh - 200px);

            .main-banner-image {
                width: 100%;
                height: 100%;
                background-image: url(../../assets/images/loginPage.svg);
                background-size: contain;
                background-repeat: no-repeat;
            }

            span {
                display: none;
            }
        }

        .welcome-text {
            position: absolute;
            bottom: 10px;
            width: 100%;

            h1 {
                width: 81%;
                margin: auto;
                font-size: 2.25rem;
                font-weight: 600;
                line-height: 44px;
                color: $textColor;

                @media (max-width: 1280px) and (max-height: 600px) {
                    font-size: 1.5rem;
                    line-height: 1.75rem;
                }

                @media (max-width: 1024px) and (max-height: 600px) {
                    font-size: 1.2rem;
                    line-height: 1.4rem;
                }
            }

            p {
                width: 81%;
                margin: auto;
                margin-bottom: 0;
                line-height: 24px;
                font-weight: 400;
                font-size: 1rem;
                color: $greyColor;
                text-align: justify;

                @media (max-width: 1200px) and (max-height: 600px) {
                    font-size: 1rem;
                    line-height: 1.25rem;
                }

                @media (max-width: 1024px) and (max-height: 600px) {
                    font-size: 0.8rem;
                    line-height: 1rem;
                }
            }
        }
    }

    .auth-right-side {
        position: relative;
        scrollbar-width: thin;
        width: 55%;
        height: 95vh;
        overflow: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        .right-inside {
            width: 72%;
            height: 100%;
            margin: auto;
            justify-content: center;
            display: flex;
            // margin-top: 21%;

            &::-webkit-scrollbar {
                display: none;
            }

            .first-wrapper {
                .auth-welcome-text {
                    text-align: center;

                    h1 {
                        font-weight: 600;
                        font-size: 2.25rem;
                        line-height: 44px;
                        color: $textColor;
                        margin-bottom: 10px;
                        width: 100%;
                        text-align: center;
                    }

                    p {
                        font-weight: 400;
                        font-size: 1.125rem;
                        line-height: 28px;
                        color: $greyColor;
                        width: 100%;
                        text-align: center;
                        margin-bottom: 0px;
                    }
                }
            }

            .second-wrapper {
                margin-top: 6%;

                .form-input-inner-inputs {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: baseline;

                    .forgot-password {
                        font-weight: 600;
                        font-size: 1rem;
                        line-height: 24px;
                        cursor: pointer;
                        color: $themeColor;
                    }

                }
            }

            .form-input-inner-inputs {
                .checkbox-wrapper {
                    margin-bottom: 0;
                }
            }

            .signup {
                margin-top: 4%;
                text-align: center;

                .signup-txt {
                    font-weight: 600;
                    font-size: 1rem;
                    line-height: 24px;
                    cursor: pointer;
                    color: $themeColor;
                }
            }

            .other-sign-in-options {
                width: 100%;
                margin-top: 6%;

                .sign-in-with {
                    display: flex;
                    justify-content: space-between;
                    align-items: baseline;
                    width: 100%;
                    margin-bottom: 5%;
                    text-align: center;

                    .text {
                        color: $greyColor;
                        font-size: 1rem;
                    }
                }

                .sign-in-options {
                    display: flex;
                    align-items: center;
                    justify-content: space-evenly;
                    width: 100%;

                    div {
                        width: 100%;
                        div:first-child {
                            background: $whiteColor;
                            border: 1px solid $greyWhiteColor;
                            border-radius: 10px;
                            width: 100%;
                            height: 54px;
                            padding: 10px;
                            cursor: pointer;
                            text-align: center;

                            .social-login {
                                margin-left: 10px;
                            }
                        }

                        div:second-child {
                            background-color: $whiteColor;
                            border: 1px solid $greyWhiteColor;
                            border-radius: 10px;
                            width: 110%;
                            height: 54px;
                            padding: 10px;
                            cursor: pointer;
                            text-align: center;

                            .social-login {
                                margin-left: 10px;
                            }

                            @media (max-width: 1024px) {
                                width: 90%;
                            }
                        }

                        @media (max-width: 1366px) and (max-height: 800px) {
                            font-size: 14px;
                            height: 6.27vh;
                        }
                    }

                }

                @media (max-width: 1366px) and (max-height: 800px) {
                    margin-top: 2%;
                }
            }

            .email {
                color: $textColor;
            }

            .second-wrapper-otp {
                display: flex;
                margin-top: 30px;
                margin-bottom: 30px;
            }

            .second-wrapper-otp>div {
                margin: auto;

                input[type=password] {
                    width: 60px !important;
                    height: 60px !important;
                    border: 1px solid $lightGreyColor;
                    outline: 1px solid $lightGreyColor;
                    border-radius: 10px;

                    &:last-child {
                        margin: 0 !important;
                    }
                }

                input[type=password]:focus {
                    border: 1px solid $themeColor;
                    outline: 1px solid $themeColor;
                    border-radius: 10px;
                }
            }

            .countdown-txt {
                text-align: center;
                margin-top: 30px;

                .disabled {
                    color: $greyColor;
                    text-decoration: none;
                    cursor: not-allowed;
                }

                .enabled {
                    color: $themeColor;
                    text-decoration: none;
                    cursor: pointer;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

        }

        // @media (max-width: 1366px) and (max-height: 800px) {
        //     overflow: auto;
        //     height: 90vh;
        // }

        // @media (max-width: 1680px) and (max-height: 800px) {
        //     height: calc(100vh - 85px);
        //     overflow: auto;
        // }

    }

    .bottom-wrapper {
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: space-between;
        width: 50%;
        margin-bottom: 5px;
        margin-left: 4%;
        right: 2%;

        .privacy-text {
            font-weight: 400;
            font-size: 0.875rem;

            span {
                text-decoration: underline;
                cursor: pointer;

                &:hover {
                    color: $themeColor;
                }
            }
        }

        .copyright {
            color: $greyColor;
            font-weight: 400;
            font-size: 10px;
            line-height: 20px;
        }
    }


    &.signup-page-section {
        .auth-left-side {
            .main-banner {
                height: 43%;
                max-height: calc(100vh - 220px)
            }
        }
    }

    &.forgot-password-page-section,
    &.signup-page-section {
        .auth-right-side {
            position: relative;
            scrollbar-width: thin;
            width: 55%;
            overflow: auto;

            &::-webkit-scrollbar {
                display: none;
            }

            .right-inside {
                width: 63%;
                margin: auto;
                height: 100%;
                display: flex;
                justify-content: center;
                // margin-top: 19%;

                &::-webkit-scrollbar {
                    display: none;
                }

                .first-wrapper {
                    .auth-welcome-text {
                        text-align: center;

                        h1 {
                            font-weight: 600;
                            font-size: 2.25rem;
                            line-height: 44px;
                            color: #1A1E29;
                            margin-bottom: 10px;
                            width: 100%;
                            text-align: center;

                            @media (max-width: 1364px) {
                                font-size: 2rem;
                                line-height: 40px;
                            }

                            @media (max-width: 1364px) {
                                font-size: 1.75rem;
                                line-height: 32px;
                            }
                        }

                        p {
                            font-weight: 400;
                            font-size: 1.125rem;
                            line-height: 28px;
                            color: $greyColor;
                            width: 100%;
                            text-align: center;
                            margin-bottom: 0px;

                            @media (max-width: 1364px) {
                                font-size: 1rem;
                                line-height: 22px;
                            }

                            @media (max-width: 1364px) {
                                font-size: .875rem;
                                line-height: 18px;
                            }
                        }
                    }
                }

                .second-wrapper {
                    margin-top: 6%;

                    .terms-line {
                        text-align: center;
                        font-size: 14px;
                        line-height: 20px;
                        color: $lightGreyColor;
                        font-weight: 400;
                        margin: 25px 0;

                        span {
                            cursor: pointer;
                            color: $themeColor;
                        }
                    }

                    .uiba-btn {
                        margin-top: 6.5%;
                    }

                    .remember {
                        margin-top: 6.5%;
                        text-align: center;
                        font-weight: 400;
                        font-size: 14px;
                        color: $textColor;

                        span {
                            cursor: pointer;
                            color: $themeColor;
                            font-weight: 600;
                        }
                    }

                    .account-exist {
                        margin-top: 6.5%;
                        text-align: center;
                        font-weight: 600;
                        font-size: 14px;
                        color: $greyColor;

                        span {
                            color: $themeColor;
                            cursor: pointer;
                        }
                    }

                    .form-input-inner-inputs {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: baseline;


                    }

                }

                // @media (max-width: 1366px) and (max-height: 800px) {
                //     overflow: auto;
                //     height: 90vh;
                // }

                // @media (max-width: 1680px) and (max-height: 800px) {
                //     height: calc(100vh - 85px);
                //     overflow: auto;
                // }

                // @media (max-width: 1920px) and (max-height: 970px) {
                //     height: calc(100vh - 260px);
                //     overflow: auto;
                // }
            }
        }

    }

    &.confirm-password-section {
        .sign-in {
            margin-top: 4%;
            text-align: center;
            cursor: pointer;
            font-weight: 600;
            font-size: 1rem;
            line-height: 24px;
            color: $themeColor;
        }
    }

}
