@import '../../assets/scss/mixin.scss';

.privacy-terms-modal {
    position: relative;

    .modal-dialog {
        min-width: 584px;
    }

    .modal-header {
        border-bottom: none;

        .modal-title {
            font-size: 1.5rem;
            font-weight: 600;
            line-height: 2rem;
        }

        .close-icon {
            position: absolute;
            top: 1.25rem;
            right: 1.25rem;
            width: 1.5rem;
            height: 1.5rem;
            cursor: pointer;
        }
    }

    .modal-body {
        padding-top: 0;
        max-height: 600px;
        max-width: 627px;
        overflow: auto;
        overflow-y: scroll;
        stroke-width: none;

        @include scroll-bar-style();

        @media (max-width: 1680px) and (max-height: 800px) {
            max-height: 450px;
        }

        p {
            margin-bottom: 1rem;
        }

        .main-text {
            margin-bottom: 20px;
            font-size: 1rem;
            font-weight: 600
        }

        p {
            font-size: 1rem;
            line-height: 1.25rem;
        }

        ul,
        ol {
            font-size: 1rem;

            &.dashed {
                list-style-type: '-  ';
            }

            &.alpha-lower {
                list-style-type: lower-alpha;
            }
        }
    }
}