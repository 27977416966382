@import '../../assets/scss/theme-variable.scss';

.date-field-wrapper {

    .MuiDialogContent-root {
        &>div {
            display: flex !important;
            flex-direction: column !important;
        }
    }

    .MuiPickersToolbar-root {
        max-width: 100% !important;
    }

    .MuiPickersToolbar-content {
        display: flex !important;
        justify-content: space-between !important;
        flex-direction: row !important;
    }

    .MuiDialog-paper {
        width: 360px !important;
        background: linear-gradient(0deg, rgba(147, 45, 177, 0.11), rgba(147, 45, 177, 0.11)), #FFFBFF;
        border-radius: 28px !important;
        font-family: 'Inter' !important;
    }


    .MuiDatePickerToolbar-title {
        margin: unset !important;
    }

    .MuiPickersDay-root {
        background-color: unset !important;

        &.Mui-selected {
            background-color: $themeColor !important;
        }
    }


    .MuiPickersDay-root {
        &.Mui-selected {
            background-color: $themeColor !important;
            color: $whiteColor !important;
        }
    }

    .MuiPickersDay-root {
        &:not(.Mui-selected) {
            background-color: unset !important;
        }
    }


    .MuiPickersDay-today {
        border: 1px solid $themeColor !important;
        color: $themeColor !important;
    }

    .MuiPickersDay-dayWithMargin {
        color: $textColor;
        font-weight: 400 !important;
        font-size: 16px !important;
        text-transform: capitalize !important;
    }

    .MuiTypography-caption {
        color: $textColor !important;
        font-weight: 400 !important;
        font-size: 16px !important;
    }

    .MuiButton-root {
        color: $themeColor !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        text-transform: capitalize !important;
    }

    .MuiTypography-overline {
        color: #504539 !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        text-transform: capitalize !important;
    }

    .MuiDatePickerToolbar-title {
        font-size: 32px !important;
        font-weight: 400 !important;
        margin: unset !important;
        color: $textColor !important;
    }
}