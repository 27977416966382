@import '../../../assets/scss/theme-variable.scss';

.job-details-container {
    width: 100%;

    .header {
        width: 100%;
        background-color: $whiteColor;
        padding: 12px 40px 0;
        border-bottom: 1px solid $lightGreyColor;

        .back-option {
            color: $themeColor;
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 1.5rem;
            cursor: pointer;
            width: max-content;

            &:hover {
                text-decoration: underline;
            }
        }

        .heading-div {
            display: flex;
            flex-direction: column;
            padding: 12px 0;

            h3 {
                color: $textColor;
                font-size: 2.125rem;
                font-weight: 600;
                line-height: 2.75rem;
            }

            .job-title-with-action {
                display: flex;
                align-items: baseline;

                .action-icons {
                    margin-left: 20px;
                    display: flex;
                }
            }
            .job-info {
                color: $greyColor;
                font-weight: 400;
                font-size: 0.75rem;
                line-height: 0.9375rem;
            }

            .published-by {
                display: flex;
                padding-top: 5px;
                gap: 10px;
                font-size: 0.875rem;

                .company,
                .location,
                .posted-date {
                    display: flex;
                    align-items: center;
                    color: $greyColor;

                    .name {
                        padding-left: 9px;
                    }
                }

                .location {
                    .location-name {
                        padding-left: 9px;
                    }
                }
            }
        }
    }

    .job-details-section {
        background-color: $offWhiteColor;
        padding: 30px 40px;
        width: 100%;

        .job-details-section-data {
            width: 85%;
            max-width: 850px;
            display: flex;
            flex-direction: column;

            .card-section {
                .section-header {
                    padding: 10px 30px 0;
                    font-weight: 600;
                    font-size: 1.25rem;
                    line-height: 1.75rem;
                    margin-bottom: 15px;
                    margin-top: 20px;
                    border-top: 1px solid $lightGreyColor;
                }

                &:first-child .section-header {
                    border: none;
                }

                .profile-details-card__body {
                    border: none;

                    .profile-details-card__body__skills {
                        padding: 0 30px;
                    }
                }

                .description {
                    padding: 0 30px;

                    .head {
                        font-weight: 600;
                        font-size: 0.875rem;
                        line-height: 1.25rem;
                        color: $textColor;
                    }

                    .job-description {
                        padding-top: 5px;
                        font-weight: 400;
                        font-size: 0.75rem;
                        line-height: 1.25rem;
                        color: $greyColor;
                    }
                }

                .details-section {
                    padding: 0 30px;
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    font-weight: 400;
                    font-size: 0.75rem;
                    line-height: 1.25rem;
                    color: $greyColor;
                    margin: 15px 0;

                    .section-column {
                        font-size: 0.875rem;
                        width: 100%;

                        .head {
                            font-weight: 600;
                            font-size: 0.875rem;
                            line-height: 1.5rem;
                            color: $textColor;
                        }
                    }
                }
            }
        }
    }
}
