@import '../../assets/scss/theme-variable.scss';

.candidate-job-card {
    width: 100%;
    padding: 30px;
    cursor: pointer;
    border-bottom: 1px solid $lightGreyColor;

    &:last-child {
        border: 0;
        border-radius: 0 0 0 10px;
    }

    &:hover,
    &.active {
        background: $activeBorderColor;

        &:first-child {
            border-radius: 10px 0 0 0;
        }
    }

    .job-title {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: baseline;
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 1.75rem;
        color: $themeColor;

        .job-name {
            word-break: break-all;
        }

        .action-btns {
            display: flex;

            .like-job {
                background: $whiteColor;
                border: 1px solid $lightGreyColor;
                border-radius: 100px;
                width: 35px;
                height: 35px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                color: $themeColor;
            }

            .compare-job {
                margin-left: 9px;
                width: 111px;
                height: 35px;
                background: $whiteColor;
                border: 1px solid $lightGreyColor;
                border-radius: 100px;
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                cursor: pointer;
            }
        }
    }

    .job-info {
        color: $greyColor;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 0.9375rem;
        padding-top: 10px;
    }

    .job-description {
        color: $textColor;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
        padding-top: 15px;
    }

    .skills {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        flex: auto;
        padding-top: 15px;
        flex-wrap: wrap;
        gap: 10px;

        .job-skill {
            width: max-content;
            height: 30px;
            padding: 5px 10px;
            background: $tagColor;
            border-radius: 50px;
            font-weight: 600;
            font-size: 0.75rem;
            line-height: 1.25rem;
            color: $textColor;
        }
    }

    .job-footer {
        display: flex;
        justify-content: flex-start;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 0.9375rem;
        color: $greyColor;
        align-items: center;
        padding-top: 15px;
        min-width: max-content;

        .company-name,
        .job-location {
            display: flex;
            text-align: center;
            align-items: flex-end;
            justify-content: space-between;
            min-width: max-content;

            .name,
            .location {
                padding-left: 6px;
            }
        }

        .job-location {
            padding: 0;
        }
    }
}