.placeholder-card {
    display: flex;
    gap: 20px;
    margin-bottom: 30px;

    .icon-section {
        display: flex;
        align-items: flex-start;
    }

    .content-section {
        display: flex;
        width: 75%;
        flex-direction: column;
    }

    .row-content {
        display: flex;
        width: 80%;
        gap: 20px;

        .text-content {
            display: flex;
            width: 50%;
            flex-direction: column;
        }
    }
}