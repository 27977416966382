@import '../../assets/scss/theme-variable.scss';
@import '../../assets/scss/mixin.scss';

.edit-modal {
    .modal-dialog {
        width: 486px;
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;

        .modal-content {
            border-radius: 16px;
            background-color: $bodyBackgroundColor;
            border: none;
        }
    }

    .modal-header {
        border-bottom: none;
        padding: 24px 21px 0 24px;

        .modal-title {
            display: flex;
            justify-content: space-between;
            width: 100%;

            h1 {
                font-size: 1.125rem;
                font-weight: 600;
                margin-bottom: 0;
            }

            .close-icon {
                cursor: pointer;
                display: flex;
            }
        }
    }

    .modal-body {
        padding: 0;
    }

    .profile-body-input-wrapper {
        padding: 24px;

        .info-text {
            color: $greyColor;
            font-size: 1rem;
            margin-bottom: 30px
        }

        .input-wrapper {
            max-height: 550px;
            overflow: auto;
            scrollbar-width: none;
            padding: 10px 10px 0 0;

            @include scroll-bar-style();

            @media (max-height: 750px) {
                max-height: 400px;
                overflow: auto;
            }

            .checkbox-wrapper {
                margin-bottom: 24px;

                .MuiFormControlLabel-label {
                    color: $textColor;
                    font-weight: 400;
                    font-size: 0.875rem;
                    width: 100%;
                    line-height: 21px;
                }
            }

            .phone-code-number {
                display: flex;

                .phone-code {
                    width: 175px;
                    margin-right: 15px;

                    .MuiOutlinedInput-root {
                        padding-right: 25px
                    }
                }

                .phone-number {
                    width: calc(100% - 125px);
                }
            }

            .chip-tag {
                border: 1px solid transparent;
                border-radius: 27px;
                padding: 10px 12px;
                width: fit-content;
                cursor: pointer;
                margin-right: 8px;
                margin-bottom: 16px;
                background-color: $tagColor;
                height: 40px;

                &:last-of-type {
                    margin-right: 0;
                }

                &__title {
                    font-size: 0.875rem;
                    font-weight: 600;
                    padding-right: 8px;
                    max-width: 350px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                &__edit-img {
                    padding-left: 8px;
                    min-height: 26px;
                    width: 26px;
                    margin-left: 0;
                    cursor: pointer;
                }
            }
        }

        .button-wrapper {
            border-top: 1px solid $lightGreyColor;
            padding-top: 30px;
            margin-top: 10px;
            width: 100%;
            display: flex;
            justify-content: flex-end;
            gap: 10px;

            .uiba-btn {
                min-width: 150px;
            }

            .delete-btn {
                background-color: $errorColor;
            }
        }

        &.skill-input-wrapper,
        &.language-input-wrapper {

            .skill-slider,
            .language-slider {
                h2 {
                    font-size: 0.875rem;
                    font-weight: 600;
                    margin-bottom: 15px;
                }
            }

            .language-slider {
                .range-slider-wrapper {
                    padding: 0px 35px;
                }
            }
        }

        &.profile-experience-input-wrapper,
        &.profile-education-input-wrapper {
            .button-wrapper {
                gap: 8px;
            }
        }

        &.feedback-input-wrapper {
            // .input-wrapper {
            //     padding-top: 0;
            // }

            .feedback-message {
                margin-bottom: 30px;
            }
        }

        &.logout-input-wrapper,
        &.delete-input-wrapper,
        &.password-success-input-wrapper {
            padding-top: 0px;

            .main-title {
                font-size: 28px;
                font-weight: 500;
                margin-bottom: 26px;
                text-align: center;
                line-height: 36px;
            }

            .button-wrapper {
                justify-content: center;
                gap: 8px;
            }

            .text {
                color: $textColor;
            }
        }

        &.profile-hobby-input-wrapper {
            .input-field-wrapper {
                margin-bottom: 32px;
            }
        }

        &.jobs-filter-wrapper {
            .input-field-wrapper {
                margin-bottom: 32px;
            }
        }
    }

    .candidate-modal-body-wrapper {
        padding: 0 24px 24px;

        .main-title {
            font-size: 28px;
            font-weight: 500;
            margin-bottom: 18px;
        }

        .main-text {
            color: $textColor;
            margin-bottom: 24px;
        }

        .button-wrapper {
            gap: 8px;

            .uiba-btn {
                background-color: $themeColor;

                &.disabled-btn {
                    background-color: rgba(28, 27, 31, 0.12);
                    color: rgba($color: $textColor, $alpha: 0.38);
                    pointer-events: none;
                }

                &.transparent-btn {
                    background: transparent;
                    border: 1px solid $themeColor;
                    color: $themeColor;
                }
            }
        }
    }

    &.delete-modal {
        .delete-input-wrapper {
            .text {
                margin-bottom: 26px;
            }
        }
    }

    .MuiChip-root {
        margin-bottom: 12px;
        margin-right: 8px;
        margin-left: 0;
    }

}