@import '../../assets/scss/theme-variable.scss';

.search-field-wrapper {
    display: flex;

    // justify-content: space-between;
    .paper-class {
        flex: auto;
        border: 1px solid $semiGreyColor;
        box-shadow: none;
        border-radius: 10px;

        &:focus-within {
            border: 1px solid $themeColor;
        }

        >.MuiInputBase-root {

            >input {
                font-weight: 400;
                font-size: 1rem;
                line-height: 1.5rem;

                &::placeholder {
                    color: $semiGreyColor;
                }
            }
        }

        .search-icon-btn,
        .clear-icon-btn {
            color: $semiGreyColor;
        }

        .clear-icon-btn {
            cursor: pointer;

            &:hover {
                color: $themeColor;
            }
        }

    }

    .custom-search {
        width: 124px;
        font-size: 1rem;
    }
}