@import '../../assets/scss/theme-variable.scss';

// Profile Edited Card
.profile-details-card {
    width: 100%;
    box-shadow: unset !important;
    padding-bottom: 0;

    &::before {
        display: none !important;
    }

    &:not(:last-child)::after {
        position: absolute;
        left: 0;
        bottom: 1px;
        right: 0;
        height: 1px;
        content: "";
        opacity: 1;
        background-color: #dde2e5;
    }

    div.MuiAccordionDetails-root {
        padding-bottom: 0;
    }

    &__title {
        font-size: 1.25rem;
        font-weight: 600;
        color: $textColor
    }

    &__body {
        border-bottom: 1px solid $activeBorderColor;
        margin-top: 15px;

        &__skills-graph {
            display: flex;
            justify-content: center;
        }

        &__skills,
        &__certificate,
        &__language,
        &__hobbies {
            flex-wrap: wrap;
            justify-content: flex-start;
            list-style: none;
            padding-left: 0;
            margin-bottom: 15px;

            &__tag {
                border: 1px solid transparent;
                border-radius: 27px;
                padding: 10px 12px;
                width: fit-content;
                cursor: pointer;
                margin-right: 8px;
                margin-bottom: 16px;
                background-color: $tagColor;
                height: 40px;

                &:last-of-type {
                    margin-right: 0;
                }

                &__title {
                    font-size: 0.75rem;
                    font-weight: 600;
                    padding-right: 8px;
                }

                &__rating {
                    margin: 0 8px;
                    background-color: $whiteColor;
                    border-radius: 14px;
                    padding: 0;
                    padding-left: 5px;
                    height: 24px;
                    width: 80px;

                    span {
                        font-size: 14px;
                        font-weight: 400;
                        margin-left: 4px;
                    }
                }

                &__edit-img {
                    padding-left: 8px;
                    min-height: 26px;
                    width: 26px;
                    margin-left: 0;
                    cursor: pointer;
                }
            }
        }

        &__social-media-logo {
            display: flex;
            margin: 0 0 30px;
            gap: 20px;

            &__icon {
                width: 24px;
                height: 24px;
                text-decoration: none;
                cursor: pointer;

                >svg {
                    width: 24px;
                    height: 24px;
                }

                &.linked-in {
                    color: $themeColor;
                }

                &.github {
                    color: #24292f;
                }
            }
        }

        &__social-media {
            display: flex;
            flex-direction: column;
            margin: 15px 0 0;

            &__content {
                display: flex;
                padding-left: 15px;
                width: 100%;
                align-items: center;

                &__title {
                    width: 250px;
                    color: $greyColor;
                    font-weight: 600;
                    font-size: 1.25rem;
                    line-height: 1.75rem;
                    min-height: 56px;
                    margin-bottom: 28px;
                    display: flex;
                    align-items: center;

                    @media (max-width: 1366px) and (max-height: 800px) {
                        min-height: unset !important;
                        height: 50px !important;
                    }
                }

                &__input-text {
                    width: calc(100% - 250px);

                    .input-field-wrapper .MuiInputBase-input {
                        color: $semiGreyColor;
                        font-size: 1rem;
                    }
                }
            }

            &__action {
                margin: auto;
                margin-top: 10px;
                margin-bottom: 20px;

                .save-btn {
                    width: 110px;
                }
            }
        }

        &__experience,
        &__education,
        &__project {
            display: flex;
            margin: 15px 0 30px;

            &__icon {
                align-items: flex-start;
                display: flex;
            }

            &__content {
                display: flex;
                padding-left: 15px;
                width: 100%;

                &__title {
                    width: 100%;
                    margin-bottom: 5px;

                    &__text,
                    &__school {
                        font-size: 1rem;
                        font-weight: 600;
                        color: $textColor;
                    }

                    &__edit {
                        font-weight: 400;
                        font-size: 0.875rem;
                        color: $greyColor;
                        display: flex;
                        align-items: center;
                        cursor: pointer;

                        svg {
                            height: 13.5px;
                            width: 13.5px;
                        }
                    }
                }

                &__degree,
                &__date,
                &__subject,
                &__url {
                    color: $textColor;
                    font-size: 0.875rem;
                    height: 20px;
                    font-weight: 400;
                    margin-bottom: 5px;
                }

                &__date,
                &__subject {
                    color: $greyColor;

                    &__title {
                        font-weight: 600;
                        margin-right: 5px;
                    }
                }

                &__sub-title {
                    width: 100%;
                    margin-bottom: 5px;

                    &__company {
                        color: $themeColor;
                        font-size: 0.875rem;
                        height: 20px;
                        font-weight: 400;
                        padding: 5px 10px 5px 0;
                        border-right: 1px solid $lightGreyColor;
                    }

                    &__date {
                        color: $greyColor;
                        font-size: 0.875rem;
                        height: 20px;
                        font-weight: 400;
                        padding: 5px 10px;
                    }
                }

                &__description {
                    width: 100%;
                    font-weight: 400;
                    font-size: 0.875rem;
                    color: $textColor;
                    white-space-collapse: preserve;
                }
            }
        }

        &__salary,
        &__salary_non-edited {
            margin: 15px 0 30px;

            .title {
                margin-bottom: 10px;

                .text {
                    color: $textColor;
                    font-weight: 600;
                    font-size: 1rem;
                    line-height: 1.25rem;
                    margin-right: 10px;
                }
            }

            .sub-title-fields {
                .type {
                    p {
                        margin-bottom: 4px;
                        color: $greyColor;
                        font-size: 0.875rem;
                        font-weight: 400;
                        line-height: 1.25rem;
                    }

                    h2 {
                        margin-bottom: 0;
                        font-size: 1rem;
                        font-weight: 600;
                        color: $textColor;
                        line-height: 1.5rem;
                    }

                    &.type-with-pay {
                        margin-left: 30px;
                    }
                }

                .tag {
                    align-self: flex-end;
                    border: 1px solid transparent;
                    border-radius: 100px;
                    padding: 5px 15px;
                    width: fit-content;
                    cursor: pointer;
                    margin-left: 30px;
                    background-color: $tagColor;
                    height: 35px;
                    font-weight: 600;
                    font-size: 0.875rem;
                }
            }
        }
    }

    &__info-text {
        font-size: 1rem;
        font-weight: 400;
        color: $textInfoColor;
    }

    &__action {
        margin-top: 30px;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
    }
}