@import '../../assets/scss/theme-variable.scss';

.uiba-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    border-radius: 10px;
    padding: 10px 24px;
    width: auto;
    max-width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    outline: none;
    box-shadow: unset !important;
    border-style: unset;
    height: 54px;
    background-color: $themeColor;

    &.full-width {
        width: 100%;
    }

    &.transparent-btn {
        background: transparent !important;
        color: $themeColor;
    }

    &.transparent-outline-btn {
        background: transparent !important;
        color: $themeColor;
        border: 1px solid $themeColor;
    }

    &.disabled-btn {
        background-color: rgba(28, 27, 31, 0.12) !important;
        color: rgba($color: #1E1B1E, $alpha: 0.38) !important;
        pointer-events: none;
    }

    &.purple-btn {
        background-color: #E7DEFF !important;
        color: #1F0060;
    }

    &.black-btn {
        background-color: #2B292D;
        color: #fff;

    }

    &.radius-btn {
        border-radius: 56px;
        font-size: 16px;
        padding: 8px 30px;
    }

    &.inactive-btn {
        background-color: #5B5A78;
        color: #fff;
    }

    &.no-cursor {
        pointer-events: none;
    }

    &.red-btn {
        background-color: #830909;
    }

    &.white-btn {
        background-color: #fff;
        border-color: #6F8BDF;
        color: #6F8BDF;
    }

    &.custom-search {
        margin-left: 20px;
    }

    span {
        img {
            margin-right: 16px;

            @media (max-width: 1366px) {
                margin-right: 10px;
            }
        }
    }

    @media (max-width:1366px) {
        line-height: 18px;
        min-height: 40px;
    }

    @media (max-width: 1024px) {
        min-height: unset;
        padding: 8px 16px;
    }

    @media (max-width:768px) {
        padding: 6px 0;
        font-weight: 500;
        min-height: 34px;
    }
}