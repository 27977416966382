.radar-chart-container {
    width: 90%;
    canvas {
        height: 100% !important;
        width: 100% !important;
    }

    .radar-chart-legends {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin: 10px;
        margin-bottom: 30px;

        &-title {
            font-size: 0.875rem;
            align-items: center;
            display: flex;

            &::before {
                content: ' ';
                width: 24px;
                height: 24px;
                display: inline-flex;
                border-radius: 50%;
                margin-right: 10px;
            }

            &.legend-1 {
                &::before {
                    background-color: rgba(144, 164, 174, 0.24);
                }
            }

            &.legend-2 {
                &::before {
                    background-color: rgba(255, 173, 107, 0.24);
                }
            }
        }
    }
}
