@import '../../assets/scss/theme-variable.scss';

.uiba-modal {

    &.delete-confirm-modal,
    &.update-confirm-modal,
    &.idle-timeout-modal {
        .modal-dialog {
            height: 22%;
            width: 27%;
            top: 27%;

            .modal-header {
                display: none;
            }

            .modal-body {
                padding: 30px;
            }

            .delete-confirm-modal-body-wrapper,
            .update-confirm-modal-body-wrapper,
            .idle-timeout-modal-body-wrapper {
                &__image-container {
                    display: flex;
                    margin-bottom: 20px;
                    justify-content: center;

                    &__icon {
                        width: 70px;
                        height: 70px;
                        background-color: $lightGreyColor;
                        border-radius: 50%;
                        font-weight: 400;
                    }
                }

                &__title {
                    font-size: 1.25rem;
                    font-weight: 600;
                    line-height: 1.75rem;
                    color: $textColor;
                    text-align: center;

                }

                &__text {
                    font-size: 1rem;
                    line-height: 1.5rem;
                    text-align: center;
                    margin-top: 10px;
                    margin-bottom: 20px;
                    font-weight: 400;
                    color: grey;
                }

                .button-wrapper {
                    gap: 20px;

                    &__cancel-btn {
                        width: 154px;
                        height: 54px;
                    }

                    &__confirm-btn {
                        width: 154px;
                        height: 54px;
                        background-color: $errorColor;
                    }

                    &__update-confirm-btn {
                        width: 154px;
                        height: 54px;
                        background-color: $themeColor;
                    }
                }
            }
        }
    }

    &.idle-timeout-modal {
        .modal-dialog {
            width: 500px;
            max-width: 90%;
            margin-left: auto;
            margin-right: auto;

            .modal-header {
                display: flex;
            }

            .idle-timeout-modal-body-wrapper {
                &__title {
                    font-size: 1rem;
                    font-weight: 500;
                }

                .button-wrapper {
                    margin-top: 20px;

                    .uiba-btn {
                        width: 160px;
                    }
                }
            }
        }
    }
}