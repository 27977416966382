@import '../../assets/scss/theme-variable.scss';

.input-field-wrapper {
    position: relative;
    width: 100%;

    .MuiOutlinedInput-root {
        min-height: 56px !important;
        border-radius: 10px !important;

        &:not(.MuiInputBase-multiline) {
            @media (max-width: 1366px) and (max-height: 800px) {
                min-height: unset !important;
                height: 50px !important;
            }
        }

        &:is(.MuiInputBase-multiline) {
            min-height: 80px !important;
        }

    }

    .mui-text-area {
        .MuiOutlinedInput-root {
            min-height: 56px !important;
            border-radius: 10px !important;

            @media (max-width: 1366px) and (max-height: 800px) {
                min-height: 50px !important;
                height: unset !important;
            }
        }
    }

    .MuiInputLabel-formControl {
        color: $semiGreyColor;
        font-size: 16px;
        font-weight: 400;
    }

    .MuiOutlinedInput-notchedOutline {
        border-color: $lightGreyColor !important;
    }

    .MuiFormLabel-root {
        &.Mui-focused {
            color: $themeColor;
            font-weight: 500;
        }
    }

    .MuiInputBase-input {
        color: $textColor;
        font-weight: 400;
    }

    .Mui-focused {
        font-size: 14px !important;

        .MuiOutlinedInput-notchedOutline {
            border-color: $themeColor !important;
        }
    }

    .Mui-error {
        .MuiOutlinedInput-notchedOutline {
            border-color: $errorColor !important;
        }

    }

    &.error-field-wrapper {
        .MuiOutlinedInput-notchedOutline {
            border-color: $errorColor !important;
        }

    }


    .Mui-error {
        color: $errorColor !important;
    }

    &.search-field-wrapper {
        .MuiFormControl-root {
            margin: 0;
        }
    }
}