@import '../../assets/scss/theme-variable.scss';
@import '../../assets/scss/mixin.scss';

.rich-text-wrapper {
    border: 1px solid $lightGreyColor;
    border-radius: 10px;

    &--error {
        border: 1px solid $errorColor;
    }

    .rich-text-editor {
        min-height: 150px;
        max-height: 250px;
        padding: 0 10px;
        overflow-y: auto;

        @include scroll-bar-style();

        &::-webkit-scrollbar-thumb {
            height: 50px;
        }
    }

    .rich-text-editor-toolbar {
        border: none;
        background: none;
        border-bottom: 1px solid $lightGreyColor;
        border-radius: none;

        .rich-text-editor-toolbar-option {
            border: none;
            box-shadow: none;
            border: 1px solid transparent;

            &:hover {
                border: 1px solid $lightGreyColor;
                background-color: $offWhiteColor;
            }
        }

        .rdw-option-active {
            background-color: $greyWhiteColor;
        }

        .rich-text-editor-toolbar-option-dropdown {
            max-height: 150px;
            overflow-y: auto;

            @include scroll-bar-style();

            &::-webkit-scrollbar-thumb {
                height: 25px;
            }
        }
    }
}