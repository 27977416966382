@import '../../../assets/scss/theme-variable.scss';
@import '../../../assets/scss/mixin.scss';

.jobs-main-section {
    width: 100%;

    .header {
        width: 100%;
        background-color: $whiteColor;
        padding: 12px 40px 0;
        border-bottom: 1px solid $lightGreyColor;

        .heading-div {
            display: flex;
            justify-content: space-between;
            padding: 12px 0;

            h3 {
                color: $textColor;
                font-size: 2.125rem;
                font-weight: 600;
                line-height: 2.75rem;
            }

            .add-new-job {
                font-size: 1rem;
                width: 124px;
                padding: 10px 15px;
            }
        }

        .jobs-tab-section {
            display: flex;
            margin-top: 10px;

            .job-type-tab {
                width: auto;
                text-align: center;
                margin-right: 30px;
                cursor: pointer;

                .text {
                    position: relative;
                    transition: all 0.2s;
                    font-weight: 400;
                    font-size: 1rem;
                    line-height: 1.5rem;
                    color: $greyColor;
                    height: 40px;
                    display: inline-block;

                    &::after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        border-radius: 10px 10px 0px 0px;
                        height: 5px;
                        width: 100%;
                        opacity: 0;
                        transition: all 0.2s;
                        background-color: $themeColor;
                    }

                    &.active {
                        color: $textColor;
                        font-weight: 600;

                        &::after {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    .job-data {
        width: 100%;
        background: $offWhiteColor;
        padding: 30px 40px 10px;
        display: flex;

        .no-jobs-message {
            font-size: 2rem;
            text-align: center;
            width: 100%;
        }

        .job-data-first-column {
            width: 45%;
            background: $whiteColor;
            border: 1px solid $lightGreyColor;
            border-radius: 10px 0px 0px 10px;
            max-height: calc(100vh - 180px);
            overflow-y: auto;

            @include scroll-bar-style();

            @media screen and (max-width: 1250px) {
                width: 40%;
            }
        }

        .loader {
            margin: auto;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .job-data-second-column {
            width: 55%;
            background: $whiteColor;
            border: 1px solid $lightGreyColor;
            border-left: 0;
            border-radius: 0px 10px 10px 0px;
            height: min-content;
            max-height: calc(100vh - 180px);
            overflow-y: auto;

            @include scroll-bar-style();

            @media screen and (max-width: 1250px) {
                width: 60%;
            }

            .job-detail-description {
                display: flex;
                flex-direction: column;
                padding: 30px;
                width: auto;

                .job-title {
                    font-weight: 600;
                    font-size: 1.5rem;
                    line-height: 2rem;
                    color: $themeColor;

                    .job-name {
                        word-break: break-all;
                    }
                }

                .published-by {
                    display: flex;
                    padding-top: 15px;

                    .company,
                    .location {
                        display: flex;
                        align-items: center;

                        .name {
                            padding-left: 9px;
                        }
                    }

                    .location {
                        // padding-left: 15px;

                        .location-name {
                            padding-left: 9px;
                        }
                    }
                }

                .job-info {
                    color: $greyColor;
                    font-weight: 400;
                    font-size: 0.75rem;
                    line-height: 0.9375rem;
                    padding-top: 10px;
                }

                .action-btn-container {
                    display: flex;
                    justify-content: center;
                    gap: 10px;
                    padding-top: 30px;

                    .apply-job,
                    .save-job,
                    .compare-job {
                        width: 160px;
                        padding: 15px 10px;
                        flex-grow: 1;
                        flex-shrink: 1;
                    }

                    @media screen and (max-width: 1250px) {

                        .apply-job,
                        .compare-job {
                            width: unset;
                        }
                    }
                }

                .description,
                .salary,
                .skills,
                .match-analysis {
                    padding-top: 30px;

                    .head {
                        font-weight: 600;
                        font-size: 1.25rem;
                        line-height: 1.75rem;
                        color: $textColor;
                    }

                    .job-description {
                        padding-top: 15px;
                        font-weight: 400;
                        font-size: 0.875rem;
                        line-height: 1.25rem;
                        color: $textColor;
                    }
                }

                .salary {
                    .amount {
                        font-weight: 400;
                        padding-top: 10px;
                        font-size: 0.875rem;
                        line-height: 1.25rem;
                        color: $textColor;
                    }
                }

                .skills {
                    .expertise {
                        display: flex;
                        padding-top: 10px;
                        flex-wrap: wrap;

                        .job-skill {
                            width: max-content;
                            height: 30px;
                            padding: 5px 10px;
                            background: $tagColor;
                            border-radius: 50px;
                            font-weight: 600;
                            font-size: 0.75rem;
                            line-height: 1.25rem;
                            color: $textColor;
                            margin-right: 9px;
                            margin-bottom: 10px;
                        }
                    }
                }

                .match-analysys {}
            }
        }
    }
}
