@import '../../../assets/scss/theme-variable.scss';

.profile-section-wrapper {
    width: 100%;

    .profile-section {

        .personal-profile-section {
            display: flex;
            justify-content: space-between;
            width: 100%;
            background-color: $whiteColor;
            padding: 40px;
            border-bottom: 1px solid $lightGreyColor;

            .avatar {
                height: 100px;
                width: 100px;
                margin-right: 25px;
                font-size: 32px;
            }

            .edit-img {
                cursor: pointer;
            }

            .details-left {
                width: calc(100% - 300px);

                .details-bottom {
                    margin-top: 32px;

                    .profile-details-card__body {
                        border: none;

                        &__salary {
                            margin-bottom: 0;
                        }
                    }

                    .profile-details-card__action {
                        justify-content: flex-start;
                    }
                }

                .button-wrapper {
                    margin-top: 30px;
                    margin-bottom: 20px;
                }
            }

            .details-right {
                .button-wrapper {
                    .uiba-btn {
                        .title-text {
                            max-width: 160px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }

        .profile-detail-section {
            background-color: $offWhiteColor;
            padding: 30px 40px;
            width: 100%;

            .profile-card-wrapper {
                width: 85%;
                background: $whiteColor;
                border: 1px solid $lightGreyColor;
                border-radius: 10px;

                .MuiAccordion-root {
                    &:last-child {
                        border-radius: 10px;
                    }
                }
            }
        }
    }
}