@import '../../../assets/scss/theme-variable.scss';

.teams-list-container {
    width: 100%;

    .header {
        width: 100%;
        background-color: $whiteColor;
        padding: 12px 40px;
        border-bottom: 1px solid $lightGreyColor;

        .heading-div {
            display: flex;
            justify-content: space-between;
            padding: 12px 0;

            h3 {
                color: $textColor;
                font-size: 2.125rem;
                font-weight: 600;
                line-height: 2.75rem;
                display: inline-block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 20ch;
            }

            .add-new-user {
                font-size: 1rem;
                width: 124px;
                padding: 10px 15px;
            }
        }
    }

    .admin-table-main {
        width: 100%;

        .add-team-table {
            background-color: $offWhiteColor;
            padding: 30px 40px;
            width: 100%;
        }
    }

    .job-actions {
        color: $greyColor;
        display: flex;
        gap: 18px;
        justify-content: center;
        width: 100%;
        font-size: 1.125rem;

        .action-edit,
        .action-delete {
            cursor: pointer;
        }
    }

    .team-title-label {
        font-weight: 600;
        cursor: pointer;
        text-align: center;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        padding-right: 25px;
        word-break: break-all;
        text-align: left;

        &:hover {
            color: $themeColor;
            text-decoration: underline;
        }
    }

    .team-description {
        text-align: justify;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        padding-right: 25px;
        word-break: break-all;
        text-align: left;
    }
}
