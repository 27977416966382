.change-password-section {
    .input-wrapper {
        width: 50%;
        max-width: 100%;
        margin: auto;
        margin-top: 36px;

        .heading {
            font-size: 32px;
            font-weight: 500;
            margin-bottom: 36px;
            text-align: center;
        }

        .delete-account {
            font-size: 14px;
            color: #1d8ee6;
            margin-top: 36px;
            cursor: pointer;
            font-weight: 500;
            text-align: center;
        }
    }
}