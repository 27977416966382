@import '../../../assets/scss/theme-variable.scss';

.RecruiterProfile-section {
    width: 100%;

    .heading-div {
        display: flex;
        justify-content: space-between;
        background-color: $whiteColor;
        padding: 70px 40px 40px;
        border-bottom: 1px solid $lightGreyColor;


        h3 {
            color: $textColor;
            font-size: 2.125rem;
            font-weight: 600;
            line-height: 2.75rem;
        }
    }

    .profile-details-section {
        background-color: $offWhiteColor;
        padding: 30px 40px;
        width: 100%;

        .profile-content {
            width: 85%;
            max-width: 850px;
            padding: 25px;

            .single-line-inputs {
                display: flex;
                justify-content: space-between;
                margin-bottom: 30px;

                .input-field-wrapper:first-child {
                    margin-right: 10px
                }
            }

            .btn-wrapper {
                margin-top: 32px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 8px;

                .save-btn {
                    width: 200px;
                }
            }
        }
    }
}