@import '../../../assets/scss/theme-variable.scss';

.create-role-section {
    width: 100%;

    .heading-div {
        display: flex;
        justify-content: space-between;
        background-color: $whiteColor;
        padding: 70px 40px 40px;
        border-bottom: 1px solid $lightGreyColor;


        h3 {
            color: $textColor;
            font-size: 2.125rem;
            font-weight: 600;
            line-height: 2.75rem;
        }
    }

    .create-role-form-section {
        background-color: $offWhiteColor;
        padding: 30px 40px;
        width: 100%;

        .create-role-form {
            width: 85%;
            max-width: 850px;

            .MuiFormHelperText-root {
                color: $greyColor;
                margin-left: 0;
                font-family: Inter;
            }

            .field-label {
                font-weight: 600;
                font-size: 0.875rem;
                line-height: 1.5rem;
                margin-bottom: 10px;
            }

            .section-header {
                font-weight: 600;
                font-size: 1.25rem;
                line-height: 1.75rem;
                margin-bottom: 30px;
            }

            .role-info-section {
                padding: 20px 20px 0 20px;
            }

            .compensation-section {
                padding: 20px;
                border: 1px solid $lightGreyColor;
                border-left: none;
                border-right: none;
            }

            .date-fields-row,
            .select-row,
            .input-row {
                display: flex;
                gap: 19px;

                .select-wrapper,
                .input-wrapper,
                .date-fields-wrapper {
                    width: 50%
                }
            }

            .radio-input-wrapper {
                span.MuiRadio-root {
                    height: 25px;
                }
            }

            .fill-wrapper {
                margin-bottom: 30px;

                .MuiChip-root {
                    font-weight: 600;
                    font-size: 0.875rem;
                    line-height: 1.5rem;
                    color: $textColor;
                    background-color: $whiteColor;
                    border: 1px solid $greyColor;
                    padding: 15px 30px;

                    &.active-chip {
                        background-color: $tagColor !important;
                        border: 1px solid $themeColor !important;
                    }

                    &.error-chip {
                        border: 1px solid $errorColor !important;
                    }
                }
            }

            .skill-wrapper {
                padding: 20px;

                .profile-details-card__body {
                    border: none;
                }
            }

            .text-editor-wrapper {
                margin-bottom: 20px;

                .RichTextEditor__root___2QXK- {
                    border: 1px solid #7E747D;
                    border-radius: 8px;
                    background: transparent;
                    padding: 16px;
                    min-height: 238px;
                    font-family: 'Roboto';

                    .EditorToolbar__root___3_Aqz {
                        border-bottom: unset;
                        margin: 0;
                    }

                    .public-DraftEditor-content {
                        padding: 0;
                    }

                    .public-DraftEditorPlaceholder-root {
                        padding: 0;
                    }
                }
            }
        }

        .bottom-button-wrapper {
            gap: 24px;
            padding: 20px;
            width: 85%;
            max-width: 850px;
            justify-content: center;

            .action-btn {
                width: 205px
            }
        }
    }
}