.app-logo {
    display: flex;
    gap: 1.4vw;
    align-items: flex-end;

    &__image {
        width: 14%;

        svg {
            width: 100%;
            height: 100%
        }
    }

    &__text {

        &__title {
            color: #386097;
            font-size: 1em;
            line-height: 120%;
            font-weight: 500;

            sup {
                left: -0.4em;
                top: -0.9em;
                font-size: 0.55em;
            }
        }

        &__subtitle {
            color: #386097;
            font-weight: 400;
            font-size: 0.45em;
            line-height: 120%;
        }
    }
}