@import '../../assets/scss/theme-variable.scss';

.upload-resume-modal {

    .modal-dialog {
        max-width: 800px;
    }

    .modal-header {
        border-bottom: 0;
        border-bottom: 0;
        padding: 1.25rem;
        padding-bottom: 0.95rem;
    }

    .modal-body {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }

    &__header {
        &__upload-text {
            font-weight: 600;
            font-size: 1.25rem;
            line-height: 1.8rem;
        }
    }

    &__dropbox {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        border: 1px dashed $greyColor;
        border-radius: 10px;
        transition: border 0.24s ease-in-out;
        justify-content: center;
        height: 11.25rem;

        &__pdf-icon {
            width: 3.125rem;
            height: 3.125rem;
            cursor: pointer;
        }

        &__drop-text {
            padding-top: 10px;
            cursor: pointer;
            color: $themeColor;
        }
    }

    &__file-accept {
        margin-top: 0.5rem;
        font-size: 0.75rem;
        padding-left: 1rem;
        color: $themeColor;
        list-style: none;
    }

    &__file-reject,
    &__error {
        color: #BA1A1A;
        margin-top: 0.5rem;
        font-size: 0.75rem;
        padding-left: 1rem;
        list-style: none;
    }

    &__file-type-info {
        color: $greyColor;
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 1rem;
        margin-top: 1.875rem;
        margin-bottom: 0.875rem;
    }
}