@import '../../assets/scss/theme-variable.scss';

.profile-detail-card {

    .profile-detail-card__body {
        margin-top: 20px;
        margin-bottom: 20px;

        .sub-title-fields {
            margin-top: 20px;

            .type {
                width: 48%;

                &:not(:first-child) {
                    margin-left: 10px;
                }

                p {
                    margin-bottom: 4px;
                    color: $greyColor;
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 1.25rem;
                }

                h2 {
                    margin-bottom: 0;
                    font-size: 1rem;
                    font-weight: 600;
                    color: $textColor;
                    line-height: 1.5rem;
                    word-break: break-all;
                    white-space: break-spaces;
                }
            }
        }
    }

    .personal-details {
        max-width: 100%;

        .title {
            margin-bottom: 10px;

            h1 {
                font-size: 1.5rem;
                font-weight: 600;
                margin-bottom: 0;
                text-transform: capitalize;
                color: $textColor;
                margin-right: 10px;
            }
        }

        p {
            margin-bottom: 10px;
            color: $greyColor;
            font-weight: 400;
            font-size: 0.875rem;

            &.address {
                margin-bottom: 3px;
            }

            &.town-country {
                padding-left: 20px
            }

            span {
                line-height: 1.25rem;
            }

            .email-icon,
            .location-icon {
                margin-right: 5px;
            }

            .email {
                border-right: 1px solid $greyColor;
                padding-right: 6px;
                margin-right: 6px;
            }
        }
    }
}