@import '../../../assets/scss/theme-variable.scss';
@import '../../../assets/scss/mixin.scss';

.selected-profile-section {
    width: 100%;

    .MuiAccordionSummary-expandIconWrapper {
        display: none;
    }

    .section-header {
        .back-option {
            padding-left: 40px;
            padding-top: 30px;
            color: $themeColor;
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 1.5rem;
            cursor: pointer;
            width: max-content;

            &:hover {
                text-decoration: underline;
            }
        }

        .heading-div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: $whiteColor;
            padding: 40px;
            border-bottom: 1px solid $lightGreyColor;

            h3 {
                color: $textColor;
                font-size: 1.5rem;
                font-weight: 600;
                line-height: 2rem;
                display: flex;
                align-items: center;
                margin: 0;

                .avatar {
                    font-size: 1.5rem;
                    height: 100px;
                    width: 100px;
                }

                span {
                    max-width: 300px;
                    word-wrap: break-word;
                    word-break: break-all;
                }
            }

            .action-btn-section {
                display: flex;
                align-items: flex-end;
                gap: 10px;
                flex-direction: column;

                .section-title {
                    font-weight: 600;
                    font-size: 0.875rem;
                    line-height: 1.5rem;
                    color: #000
                }

                .application-status-section {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    column-gap: 12px;
                }

                .choose-apply {
                    width: 176px;
                }
            }
        }
    }

    .section-body {
        background-color: $offWhiteColor;
        padding: 30px 40px;
        width: 100%;
        display: flex;

        .menu-section {
            padding: 10px 20px 20px;
            width: 20%;

            ul {
                padding: 0;
                margin: 0;

                li {
                    font-weight: 400;
                    font-size: 0.875rem;
                    line-height: 1.25rem;
                    margin-bottom: 12px;
                    color: $greyColor;
                    list-style-type: none;
                    cursor: pointer;

                    &.active {
                        color: $themeColor;
                        font-weight: 600;
                    }
                }
            }
        }

        .content-section {
            width: 75%;
            background: $whiteColor;
            border: 1px solid $lightGreyColor;
            border-radius: 10px;

            .MuiAccordion-root {
                &:last-child {
                    border-radius: 10px;
                }
            }

            .internal-candidate-section {
                padding: 20px;
                background-color: $themeColor;
                border-bottom: 1px solid $themeColor;
                color: $whiteColor;
                font-weight: 600;
                font-size: 1rem;
                line-height: 1.5rem;

                .internal-candidate-checkbox {
                    color: $whiteColor;
                    margin: 0;

                    &:hover {
                        color: $semiGreyColor;
                    }
                }
            }

            td {
                font-size: 1rem;

                &>.job-applied {
                    font-weight: bold;
                    color: $textColor;
                }

                &>.application-status {
                    .status {
                        font-weight: 400;
                        color: $textColor;
                        text-transform: capitalize;
                    }

                    .date {
                        color: $greyColor;
                        font-weight: 400;
                    }
                }
            }

            .call-notes-section {
                display: flex;

                &__history {
                    width: 25%;
                    padding: 10px 0;

                    &__search {
                        padding: 10px;
                    }

                    &__not-found {
                        color: $darkGreyColor;
                        font-size: 0.875rem;
                        padding: 10px;
                        text-align: center;
                    }

                    &__note__wrapper {
                        display: flex;
                        flex-direction: column;
                        max-height: 70vh;
                        overflow-y: auto;

                        @include scroll-bar-style(60px);
                    }

                    &__note {
                        display: flex;
                        flex-direction: column;
                        padding: 10px;
                        border-top: 1px solid transparent;
                        border-bottom: 1px solid transparent;

                        &--active,
                        &:hover {
                            border-top: 1px solid $lightGreyColor;
                            border-bottom: 1px solid $lightGreyColor;
                            background: $activeBorderColor;
                        }

                        .title-row {
                            display: flex;
                            justify-content: space-between;
                            align-items: flex-start;

                            .title {
                                width: 75%;
                                font-size: 0.875rem;
                                line-height: 1.25rem;
                                color: $textColor;
                                font-weight: 600;
                                display: -webkit-box;
                                -webkit-line-clamp: 1;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                            }

                            .edited-time {
                                color: $greyColor;
                                font-size: 0.75rem;
                            }
                        }

                        .body-row {
                            display: flex;
                            justify-content: space-between;
                            align-items: flex-start;

                            .body {
                                font-size: 0.75rem;
                                line-height: 1.25rem;
                                color: $textColor;
                                display: -webkit-box;
                                -webkit-line-clamp: 3;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                            }

                            .action-edit {
                                font-size: 0.75rem;
                                cursor: pointer;
                                align-self: center;
                            }
                        }
                    }
                }

                &__view {
                    width: 75%;
                    border-left: 1px solid $lightGreyColor;

                    &__action {
                        padding: 10px;
                        font-size: 1rem;
                        line-height: 1.5rem;
                        margin-bottom: 10px;
                        font-weight: 500;
                        border-bottom: 1px solid $lightGreyColor;

                        span {
                            width: 70%;
                            color: $themeColor;
                            font-weight: 600;
                            display: inline-block;
                            text-overflow: ellipsis;
                        }

                        .action-clear {
                            font-weight: 400;
                            cursor: pointer;
                            float: right;
                        }
                    }

                    &__editor {
                        padding: 10px;

                        .action-save-note {
                            float: right;
                            margin-bottom: 15px;
                        }
                    }
                }
            }
        }
    }
}

#application-status-popover .MuiPopover-paper {
    width: 160px;
}
