@import '../../../assets/scss/theme-variable.scss';

.dashboard-container {
    width: 100%;
    height: max-content;
    background: $offWhiteColor;
    padding: 30px 25px 30px 40px;

    .dashboard-count-row {
        display: flex;
        width: 100%;
        justify-content: space-between;
        gap: 20px;
        margin-bottom: 30px;

        .dashboard-card {
            width: 33%;
            height: 126px;
            background: #F4F9FC;
            box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            display: flex;
            cursor: pointer;
            justify-content: center;
            align-items: center;
            gap: 20px;

            .card-icon {
                height: 50px;
                width: 50px;
            }

            .card-content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;

                .count {
                    font-weight: 600;
                    font-size: 2.25rem;
                    line-height: 2.75rem;
                    color: $dashboardTextColor;
                }

                .info {
                    font-weight: 400;
                    font-size: 1.125rem;
                    line-height: 1.75rem;
                    color: $greyColor;
                }

                &:hover {

                    .info,
                    .count {
                        color: $textInfoColor;
                    }
                }
            }
        }
    }

    .analytics-section {
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid $lightGreyColor;

        .title-row {
            display: flex;
            gap: 10px;
            align-items: center;
            color: $themeColor;

            .title {
                font-size: 1.25rem;
                font-weight: 600;
            }

            .filter {
                .filter-icon {
                    color: $greyColor;
                    cursor: pointer;
                }
            }

            .selected-date-range {
                color: $greyColor;
                font-size: 0.875rem;
                font-weight: 500;
            }
        }

        .date-range-row {
            display: flex;
            gap: 20px;
            align-items: baseline;
            margin-top: 20px;
        }
    }

    .dashboard-chart-container {
        display: flex;
        align-items: stretch;
        gap: 40px;

        &-left {
            width: 64%;

            .account-creation-chart,
            .login-activity-chart,
            .application-activity-chart,
            .review-activity-chart {
                .chart-header {
                    margin-bottom: 15px;
                    display: flex;

                    .title {
                        font-size: 1.125rem;
                        line-height: 1.75rem;
                        font-weight: 600;
                        color: $dashboardTextColor
                    }

                    .legends {
                        font-weight: 400;
                        font-size: 1rem;
                        line-height: 1.75rem;
                        color: $greyColor;
                        display: flex;
                        align-items: center;

                        .legend-title {

                            &::before {
                                content: ' ';
                                width: 10px;
                                height: 10px;
                                display: inline-flex;
                                border-radius: 50%;
                                margin-right: 10px;
                                margin-left: 25px;
                            }

                            &.account-creation-legend {
                                &::before {
                                    background-color: #C25700;
                                }
                            }

                            &.profile-creation-legend {
                                &::before {
                                    background-color: $themeColor;
                                }
                            }
                        }
                    }
                }

                .chart-area {
                    width: 100%;
                    background-color: $whiteColor;
                    padding: 20px;
                    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
                    border-radius: 10px;
                }
            }

            .account-creation-chart {
                .chart-area {
                    height: 348px;
                }
            }

            .login-activity-chart {
                margin-top: 40px;

                .chart-area {
                    height: 245px;
                }
            }

            .application-review-activity-row {
                display: flex;
                gap: 30px;
                margin-top: 40px;

                .application-activity-chart {
                    width: 58%;

                    .chart-area {
                        height: 260px;
                        justify-content: center;
                        display: flex;
                        flex-direction: column;

                        .header-row {
                            display: flex;
                            justify-content: space-between;
                            margin-top: 15px;

                            .title,
                            .value {
                                font-size: 1rem;
                                line-height: 1.75rem;
                                color: $greyColor
                            }
                        }

                        .progress-bar-wrapper {
                            width: 100%;

                            .progress {
                                margin: 0;
                            }

                            .label {
                                display: none;
                            }
                        }

                        .invited-interview-bar .progress-bar {
                            background-color: $chartGreenColor;
                        }

                        .hired-bar .progress-bar {
                            background-color: $chartOrangeColor;
                        }
                    }
                }

                .review-activity-chart {
                    width: 42%;

                    .chart-area {
                        height: 260px;

                        .chart-footer {
                            margin: 0 auto;
                            margin-top: 15px;
                            display: flex;
                            flex-direction: column;
                            max-width: 230px;

                            .legends {
                                font-weight: 400;
                                font-size: 1rem;
                                line-height: 1.75rem;
                                color: $greyColor;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;

                                @media (max-width: 1300px) {
                                    font-size: 0.75rem;
                                    line-height: 1.25rem;
                                }

                                .legend-title {

                                    &::before {
                                        content: ' ';
                                        width: 10px;
                                        height: 10px;
                                        display: inline-flex;
                                        border-radius: 50%;
                                        margin-right: 10px;
                                    }

                                    &.unsubscribed-legend {
                                        &::before {
                                            background-color: $chartOrangeColor;
                                        }
                                    }

                                    &.inactive-legend {
                                        &::before {
                                            background-color: $themeColor;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &-right {
            width: 33%;

            .aggregate-cohort-chart {
                display: flex;
                flex-direction: column;
                height: 100%;

                .chart-header {
                    margin-bottom: 15px;

                    .title {
                        font-size: 1.125rem;
                        line-height: 1.75rem;
                        font-weight: 600;
                        color: $dashboardTextColor
                    }
                }

                .chart-area {
                    flex: 1;
                    width: 100%;
                    background-color: $whiteColor;
                    padding: 20px;
                    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
                    border-radius: 10px;
                    display: flex;
                    flex-direction: column;
                    gap: 25px;

                    .previous-experience-chart,
                    .education-chart,
                    .certification-chart,
                    .location-chart,
                    .currency-chart,
                    .onboarding-type-chart {
                        display: flex;
                        justify-content: space-around;

                        .chart-content {
                            width: 60%;

                            .chart-area-title {
                                font-size: 0.875rem;
                                line-height: 1.75rem;
                                color: $dashboardTextColor;
                                margin-bottom: 10px;
                            }

                            .chart-area-body {
                                width: 120px;
                                height: 120px;
                            }
                        }

                        .chart-legends {
                            width: 40%;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;

                            .legends {
                                font-weight: 400;
                                font-size: 0.75rem;
                                line-height: 1.75rem;
                                color: $darkGreyColor;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;

                                @media (max-width: 1300px) {
                                    font-size: 0.6rem;
                                    line-height: 1.25rem;
                                }

                                .legend-title {

                                    &::before {
                                        content: ' ';
                                        width: 10px;
                                        height: 10px;
                                        display: inline-flex;
                                        border-radius: 50%;
                                        margin-right: 10px;
                                    }

                                    &.chart-label-orange {
                                        &::before {
                                            background-color: $chartOrangeColor;
                                        }
                                    }

                                    &.chart-label-blue {
                                        &::before {
                                            background-color: $themeColor;
                                        }
                                    }

                                    &.chart-label-yellow {
                                        &::before {
                                            background-color: $chartYellowColor;
                                        }
                                    }

                                    &.chart-label-green {
                                        &::before {
                                            background-color: $chartDarkGreenColor;
                                        }
                                    }

                                    &.chart-label-red {
                                        &::before {
                                            background-color: $chartRedColor;
                                        }
                                    }
                                }
                            }
                        }

                    }
                }
            }
        }
    }
}

.filter-popover {
    .option.active {
        color: $themeColor;
    }
}