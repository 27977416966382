@import '../../assets/scss/theme-variable.scss';

.header-section {
	border-bottom: 1px solid $lightGreyColor;
	padding: 20px 35px;
	width: 100%;
	height: 64px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	background: $whiteColor;

	.main-head {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		width: 100%;

		.header-settings {
			svg {
				cursor: pointer;
				color: grey;
			}
		}
	}
}

.MuiPopover-paper {
	background: $whiteColor;
	box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
	border-radius: 5px;
	padding: 10px;
	min-width: 120px !important;

	.option {
		margin-bottom: 10px;
		font-weight: 400;
		color: $greyColor;
		font-size: 0.875rem;
		cursor: pointer;

		&:last-of-type {
			margin-bottom: 0;
		}

		&:hover {
			color: $themeColor
		}

		&.option-selected {
			color: $themeColor
		}
	}
}
