@import '../../../assets/scss/theme-variable.scss';

.members-container {
    width: 100%;
    .header {
        width: 100%;
        background-color: $whiteColor;
        padding: 12px 40px;
        border-bottom: 1px solid $lightGreyColor;

        .heading-div {
            display: flex;
            justify-content: space-between;
            padding: 12px 0;

            h3 {
                color: $textColor;
                font-size: 2.125rem;
                font-weight: 600;
                line-height: 2.75rem;
                display:inline-block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 20ch;
            }

            .add-new-user{
                font-size: 1rem;
                width: 124px;
                padding: 10px 15px;
            }
        }
    }
    .member-section {
        background-color: $offWhiteColor;
        padding: 30px 40px;
        width: 100%;
    }
}
