@import '../../assets/scss/theme-variable.scss';

.data-table-container {
    table.MuiTable-root {
        thead.MuiTableHead-root {
            tr.MuiTableRow-head {

                th.MuiTableCell-head {
                    padding: 5px 10px;
                    background-color: $themeColor;
                    color: $whiteColor;
                    font-size: 0.875rem;
                    font-weight: 600;

                    >div {
                        padding: 10px
                    }

                    .sortable-cell {
                        display: flex;
                        width: 100%;
                        justify-content: space-between;
                        align-items: center;
                        cursor: pointer;

                        .sort-icons {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;

                            .sort-asc,
                            .sort-desc {
                                font-size: 1.4rem;
                                color: $semiGreyColor;

                                &.active {
                                    color: $whiteColor;
                                    font-weight: bold;
                                }
                            }

                            .sort-asc {
                                position: relative;
                                bottom: -8px;
                            }

                            .sort-desc {
                                position: relative;
                                top: -8px;
                            }
                        }
                    }

                    &:not(:last-child) {
                        border-right: 1px solid $themeColor;
                        border-left: 1px solid $themeColor;

                        >div {
                            border-right: 1px solid $lightBlueColor;
                        }
                    }
                }
            }
        }

        tbody.MuiTableBody-root {
            tr.MuiTableRow-root {
                td.MuiTableCell-body {
                    padding: 20px;
                }
            }
        }
    }
}