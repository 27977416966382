@mixin scroll-bar-style($height: 135px) {
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 7px;
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px #EFEFEF;
        background-color: #EFEFEF;
        border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #7E7E7E;
        border-radius: 100px;
        outline: 1px solid #7E7E7E;
        height: $height;
    }
}