$themeColor: #1D8EE6;
$lightBlueColor: #67B6FF;
$whiteColor: #FFF;
$offWhiteColor: #F9FAFC;
$greyWhiteColor: #E6E8EC;
$greyColor: #77787D;
$darkGreyColor: #6F6C6C;
$lightGreyColor: #DDE2E5;
$semiGreyColor: #B0B6BD;
$bodyBackgroundColor: #FFFBFF;
$textColor: #1A1E29;
$dashboardTextColor: #171717;
$textInfoColor: #1B91FF;
$authFormWrapperBg: $whiteColor;
$typeSelectorBg: $whiteColor;
$typeSelectorActiveBg: #FCDDBC;
$welcomeText: #F0E0D0;
$signButtonFill: #855400;
$menuActiveBg: rgba(24, 77, 252, 0.05);
$iconButtonColor: #E7ECFF;
$tagColor: #E7EEFD;
$errorColor: #B70000;
$activeBorderColor: #F5F9FF;
$chartGreenColor: #2CABA7;
$chartDarkGreenColor: #21B731;
$chartOrangeColor: #FFAD6B;
$chartYellowColor: #FFE500;
$chartRedColor: #FF2A2A