@import './theme-variable.scss';

body {
    font-family: 'Inter', sans-serif !important;
    color: $textColor;
    background-color: $bodyBackgroundColor;
}

* {
    font-family: 'Inter', sans-serif !important;
}

p {
    margin: 0;
}


.spinner-style {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.no-data {
    font-size: 32px;
}

.flex-align-center {
    display: flex;
    align-items: center;
}

.flex-align-start {
    display: flex;
    align-items: flex-start;
}

.flex-justify-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-align-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex-justify-between {
    display: flex;
    justify-content: space-between;
}

.flex-justify-around {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.flex-justify-evenly {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.flex-justify-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.column-flex {
    display: flex;
    flex-direction: column;
}

.row-flex {
    display: flex;
    flex-direction: row;
}

.error-message {
    font-size: 12px;
    color: #BA1A1A;
    opacity: 0;
    transition: all 0.2s;
    min-height: 16px;
    margin: 8px 0;
    text-align: left !important;

    @media (max-width: 1366px) {
        margin: 6px 0;
    }

    &.forgot-error {
        display: none;
    }
}

.text-center {
    text-align: center;
}

.display-hidden {
    display: none;
}

.opacity-1 {
    opacity: 1;
}

.opacity-0 {
    opacity: 0;
}

.w-100 {
    width: 100%;
}

.h-100 {
    height: 100%;
}

img {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: unset !important;
    -webkit-text-fill-color: #000;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
}

.border-0 {
    border: none;
}

.no-data {
    height: 100%;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;

    &.jobs-no-data {
        height: calc(100vh - 382px);

        .spinner-border {
            color: $themeColor;
            font-size: 20px;
        }
    }
}