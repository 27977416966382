@import '../../assets/scss/theme-variable.scss';

.progress-bar-wrapper {
    .progress {
        background: $lightGreyColor;
        border-radius: 12px;
        margin-right: 18px;
        height: 10px;

        .progress-bar {
            border-radius: 17px;
            background: $themeColor;
        }
    }

    &.disabled-row {
        .progress {
            .progress-bar {
                background-color: $greyColor;
            }
        }
    }


    .label {
        font-size: 14px;
        color: $textColor;
    }
}