@import '../../../assets/scss/theme-variable.scss';

.candidate-list-section {
    width: 100%;

    .header {
        width: 100%;
        background-color: $whiteColor;
        padding: 12px 40px 0;
        border-bottom: 1px solid $lightGreyColor;

        .back-option {
            color: $themeColor;
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 1.5rem;
            cursor: pointer;
            width: max-content;

            &:hover {
                text-decoration: underline;
            }
        }

        .heading-div {
            display: flex;
            justify-content: space-between;
            padding: 12px 0;

            h3 {
                color: $textColor;
                font-size: 2.125rem;
                font-weight: 600;
                line-height: 2.75rem;
            }

            .export-list {
                font-size: 1rem;
                width: 124px;
                padding: 10px 15px;
            }
        }

        .candidate-tab-section {
            display: flex;
            margin-top: 30px;
            justify-content: space-between;

            .candidate-type-tab {
                width: auto;
                max-width: max-content;
                text-align: center;
                cursor: pointer;
                word-break: break-all;
                word-wrap: break-word;

                .text {
                    position: relative;
                    transition: all 0.2s;
                    font-weight: 400;
                    font-size: 1rem;
                    line-height: 1.5rem;
                    color: $greyColor;
                    min-height: 40px;
                    display: inline-block;

                    @media (max-width: 1250px) {
                        font-size: 0.75rem;
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        border-radius: 10px 10px 0px 0px;
                        height: 5px;
                        width: 100%;
                        opacity: 0;
                        transition: all 0.2s;
                        background-color: $themeColor;
                    }

                    &.active {
                        color: $textColor;
                        font-weight: 600;

                        &::after {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    .candidate-bulk-assignment-section {
        .section-title {
            font-size: 14px;
            color: #77787D;
            font-weight: 300;
        }
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding-top: 12px;
        align-items: center;
        gap: 8px;
    }

    .candidates-tab-content {
        background-color: $offWhiteColor;
        padding: 30px 40px;
        width: 100%;

        .data-table-container {
            th .select-all {
                color: $whiteColor;
                margin: 0;

                &:hover {
                    color: $semiGreyColor;
                }
            }

            .MuiFormControlLabel-root {
                margin: 0;
            }

            .candidate-name {
                display: table-cell;
                vertical-align: middle;
                font-weight: 600;
                cursor: pointer;
                word-break: break-all;
                min-width: 200px;

                >span {
                    display: table-cell;
                    vertical-align: middle;
                    height: 55px;

                    &:not(.disabled-row):hover {
                        color: $themeColor;
                        text-decoration: underline;
                    }
                }

                .avatar {
                    height: 55px;
                    width: 55px;
                    margin-right: 10px;
                    float: left
                }
            }

            .disabled-row {
                color: $greyColor;
                text-decoration: none;
                cursor: not-allowed;
            }
        }
    }
}
