@import '../../assets/scss/theme-variable.scss';
@import '../../assets/scss/mixin.scss';

.sidebar {
    width: 100% !important;
    min-width: 100% !important;
    border-right: 1px solid $lightGreyColor;
    padding: 10px;
    height: 100vh;
    max-height: 100%;

    @media (max-width: 980px) {
        padding: 0;
        min-width: 180px !important;
        width: 0 !important;
    }

    &__logo {
        position: relative;
        width: 91%;
        //height: 11%;
        margin: auto;
        margin-bottom: 70px;
        margin-top: 20px;

        .app-logo {
            gap: 12px;

            &__image {
                width: 30%;
                min-height: 50px;
                min-width: 50px;
            }
        }
    }

    &.ps-collapsed {
        width: 95px !important;
        min-width: 95px !important;

        @media (max-width: 1920px) and (max-height: 600px) {
            .sidebar__logo {
                width: 95px !important;
            }
        }

        @media (max-width:1366px) {
            width: 80px !important;
            min-width: 80px !important;

            .sidebar__logo {
                width: 80px !important;
            }
        }
    }

    &.ps-toggled {
        width: 180px !important;
        left: 0 !important;
    }

    >.ps-sidebar-container {
        display: flex;
        flex-direction: column;
        height: 100vh;
        max-height: 100%;
        overflow: hidden;
        width: 100%;
        padding-top: 0;
        background: $whiteColor;

        .sidebar__menu {
            @include scroll-bar-style();

            @media (max-width:1920px) and (max-height:600px) {
                overflow: auto;
                padding-top: 0;

                >nav>ul {
                    margin-right: 3px;
                }
            }

            nav>ul .sidebar__menu__item,
            nav>ul .sidebar__menu__sub-menu {
                >.ps-menu-button {
                    border-radius: 10px;
                    height: 54px;
                    padding: 0 10px;
                    margin-bottom: 8px;
                    font-size: 0.875rem;

                    .ps-menu-icon {
                        img {
                            margin-right: 11px;
                        }
                    }

                    .ps-menu-label {
                        font-size: 14px;
                        font-weight: 400;
                        color: $greyColor;
                    }

                    .ps-submenu-expand-icon {

                        &.ps-open {
                            color: $themeColor
                        }
                    }

                    &:hover {
                        background-color: $menuActiveBg
                    }

                }

                >.ps-submenu-content {
                    .sidebar__menu__sub-menu__item {
                        >.ps-menu-button {
                            padding: 6px 10px;
                            height: 32px;
                            font-size: 0.875rem;

                            .ps-menu-label {
                                font-size: 0.875rem;
                                font-weight: 400;
                                color: $greyColor;
                            }


                        }

                        &.sidebar__menu__sub-menu__item--active {
                            >.ps-menu-button {

                                .ps-menu-label {
                                    color: $textColor;
                                    font-weight: 600;
                                }
                            }
                        }
                    }
                }

                &.sidebar__menu__item--active {
                    >.ps-menu-button {
                        background: $menuActiveBg;

                        .ps-menu-icon {
                            filter: invert(48%) sepia(76%) saturate(1889%) hue-rotate(183deg) brightness(90%) contrast(101%);
                        }

                        .ps-menu-label {
                            color: $themeColor;
                        }
                    }
                }
            }
        }
    }
}

.toggle-sidebar-icon {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 10;
}