.recruiter-modal {
    .modal-dialog {
        width: 486px;
        max-width: 100%;

        .modal-content {
            border-radius: 16px;
            background-color: #FFFBFF;
            border: none;
        }
    }

    .modal-header {
        border-bottom: none;
        padding: 24px 21px 0 24px;

        .modal-title {
            display: flex;
            justify-content: space-between;
            width: 100%;

            h1 {
                font-size: 32px;
                font-weight: 400;
                margin-bottom: 0;
            }

            .close-icon {
                cursor: pointer;
            }
        }
    }

    .modal-body {
        padding: 0;
    }

    .recruiter-modal-body-wrapper {
        padding: 24px;

        .main-title {
            font-size: 22px;
            font-weight: 400;
            text-align: center;
        }

        .text,
        .purple-text {
            color: #1E1B1E;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            margin-top: 24px;
        }

        .purple-text {
            color: #932DB1;
            margin-top: 2px;
            cursor: pointer;
        }

        .button-wrapper {
            margin-top: 24px;
            gap: 8px;

            .uiba-btn {
                background-color: #932DB1;
            }
        }

        &.export-modal-body-wrapper {
            padding-top: 0;

            .input-wrapper {
                margin-top: 32px;
            }

            .main-title {
                font-size: 28px;
                font-weight: 500;
                text-align: left;
            }

            .text {
                font-weight: 400;
                font-size: 16px;
                text-align: center;
            }
        }
    }
}