@import '../../assets/scss/theme-variable.scss';

.avatar {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    background-color: $lightGreyColor;
    font-weight: 600;
    margin-right: 1rem;
    text-transform: uppercase;
    cursor: pointer;
    color: $greyColor;
    font-size: 1.25rem;
}
