@import '../../assets/scss/theme-variable.scss';
@import '../../assets/scss/mixin.scss';

.dashboard-wrapper {
    display: flex;
    width: 100%;
    overflow: hidden;
    position: relative;
    background: $bodyBackgroundColor;

    .main-dashboard-sidebar-menu {
        width: 200px;

        @media (max-width:980px) {
            width: 0;
        }

        @media (min-width:1450px) {
            width: 300px;

            .app-logo {
                gap: 12px;
                font-size: 2.5rem !important;

                &__image {
                    width: 30%;
                    min-height: 50px;
                    min-width: 50px;
                }
            }
        }
    }

    .main-dashboard-section {
        width: calc(100% - 200px);
        display: flex;
        flex-direction: column;

        @media (max-width:980px) {
            width: 100%;
        }

        @media (min-width:1450px) {
            width: calc(100% - 300px);
        }

        .main-dashboard-header {
            height: 80px;
            width: 100%
        }

        .main-content-section {
            display: flex;
            height: calc(100vh - 80px);
            max-height: 100%;
            padding: 0;
            overflow: auto;
            width: 100%;

            @include scroll-bar-style();
        }
    }

}