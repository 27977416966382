@import '../../../../assets/scss/theme-variable.scss';


.jobs-main-section {
    width: 100%;

    .loader {
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .header {
        width: 100%;
        background-color: $whiteColor;
        padding: 12px 40px 0;
        border-bottom: 1px solid $lightGreyColor;

        .heading-div {
            display: flex;
            justify-content: space-between;
            padding: 12px 0;

            h3 {
                color: $textColor;
                font-size: 2.125rem;
                font-weight: 600;
                line-height: 2.75rem;
            }

            .add-new-job {
                font-size: 1rem;
                width: 124px;
                padding: 10px 15px;
            }
        }

        .jobs-tab-section {
            display: flex;
            margin-top: 10px;

            .job-type-tab {
                width: auto;
                text-align: center;
                margin-right: 30px;
                cursor: pointer;

                .text {
                    position: relative;
                    transition: all 0.2s;
                    font-weight: 400;
                    font-size: 1rem;
                    line-height: 1.5rem;
                    color: $greyColor;
                    height: 40px;
                    display: inline-block;

                    &::after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        border-radius: 10px 10px 0px 0px;
                        height: 5px;
                        width: 100%;
                        opacity: 0;
                        transition: all 0.2s;
                        background-color: $themeColor;
                    }

                    &.active {
                        color: $textColor;
                        font-weight: 600;

                        &::after {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    .saved-job-section {
        width: 100%;
        background: $offWhiteColor;

        .saved-job-row {
            padding: 30px 40px;
        }

        .no-jobs-message {
            text-align: center;
            width: 100%;
            font-size: 2rem;
            margin-top: 1rem;
        }

        .saved-job {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            background: $whiteColor;
            border: 1px solid $lightGreyColor;
            padding: 30px 40px;
            cursor: pointer;

            &:first-child {
                border-radius: 10px 10px 0 0;
            }

            &:last-child {
                border-radius: 0 0 10px 10px;
            }


            .job-info {
                .job-title {
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 28px;
                    color: $themeColor;
                    margin: 0;
                }

                .job-company {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: $textColor;
                }

                .job-location {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: $greyColor;
                }
            }
        }
    }

    .pagination {
        display: flex;
        justify-content: flex-end;
        padding-right: 40px;
    }
}
