@import '../../assets/scss/theme-variable.scss';

.icon-button {
    &.MuiButton-contained {
        background-color: $iconButtonColor;
        border-radius: 10px;
        height: 44px;
        font-weight: 600 !important;
        color: $themeColor;
        box-shadow: none;
        text-transform: capitalize;
        font-size: 14px;
        border: 1px solid transparent;

        &:hover {
            border: 1px solid $themeColor;
            background-color: $iconButtonColor;
            box-shadow: none;
        }
    }

    &.primary-button {
        background-color: $themeColor;
        color: $whiteColor;

        &:hover {
            border: 1px solid $themeColor;
            background-color: $themeColor;
            box-shadow: none;
        }
    }

    &.border-button {
        background-color: $whiteColor;
        border: 1px solid $themeColor;

        &:hover {
            border: 1px solid $themeColor;
            background-color: $iconButtonColor;
            box-shadow: none;
        }
    }
}