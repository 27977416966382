@import '../../../assets/scss/theme-variable.scss';

.RecruiterProfile-section {
    width: 100%;

    .heading-div {
        display: flex;
        justify-content: space-between;
        background-color: $whiteColor;
        padding: 70px 40px 40px;
        border-bottom: 1px solid $lightGreyColor;


        h3 {
            color: $textColor;
            font-size: 2.125rem;
            font-weight: 600;
            line-height: 2.75rem;
        }
    }

    .profile-details-section {
        background-color: $offWhiteColor;
        padding: 30px 40px;
        width: 100%;

        .profile-content {
            width: 85%;
            max-width: 850px;
            padding: 25px;

            .sub-text {
                display: flex;
                align-items: center;
                margin-top: 15px;
                margin-bottom: 16px;

                .sub-text-icon {
                    img {
                        width: 50px;
                        height: 50px;
                    }

                    margin-right: 10px;
                }

                .sub-text-content {
                    display: flex;
                    flex-direction: column;


                    label {
                        font-size: 0.875rem;
                        font-weight: 400;
                        color: $greyColor;
                        line-height: 1.25rem;
                        margin-bottom: 4px;

                    }

                    .sub-heading {
                        font-size: 1rem;
                        line-height: 1.5rem;
                        font-weight: 500;
                        color: $textColor;
                        margin: 0px;
                    }
                }
            }

            .phone-code-number {
                display: flex;

                .phone-code {
                    width: 175px;
                    margin-right: 15px;

                    .MuiOutlinedInput-root {
                        padding-right: 25px
                    }
                }

                .phone-number {
                    width: calc(100% - 125px);
                }
            }
        }
    }
}
