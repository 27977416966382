@import '../../../assets/scss/theme-variable.scss';

.admin-users-container {
    width: 100%;

    .header {
        width: 100%;
        background-color: $whiteColor;
        padding: 12px 40px;
        border-bottom: 1px solid $lightGreyColor;

        .heading-div {
            display: flex;
            justify-content: space-between;
            padding: 12px 0;

            h3 {
                color: $textColor;
                font-size: 2.125rem;
                font-weight: 600;
                line-height: 2.75rem;
            }

            .add-new-user {
                font-size: 1rem;
                width: 124px;
                padding: 10px 15px;
            }
        }
    }

    .admin-users-section {
        background-color: $offWhiteColor;
        padding: 30px 40px;
        width: 100%;
    }

    .job-actions {
        color: $greyColor;
        display: flex;
        gap: 18px;
        justify-content: center;
        width: 100%;
        font-size: 1.125rem;

        .action-edit,
        .action-delete {
            cursor: pointer;
        }
    }

    .user-title-label {
        display: table-cell;
        vertical-align: middle;
        font-weight: 600;
        cursor: pointer;
        word-break: break-all;
        min-width: 200px;

        >span {
            display: table-cell;
            vertical-align: middle;
            height: 55px;

            &:not(.disabled-row):hover {
                color: $themeColor;
                text-decoration: underline;
            }

            &.disabled-row {
                font-weight: 400;
                cursor: not-allowed;
            }
        }

        .avatar {
            height: 55px;
            width: 55px;
            margin-right: 10px;
            float: left
        }
    }
}
