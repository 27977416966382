@import '../../assets/scss/theme-variable.scss';

.range-slider-wrapper {
    padding: 0 30px;

    .MuiSlider-thumb {
        background-color: $whiteColor;
        border: 5px solid $themeColor;

        &:hover {
            box-shadow: none;
        }
    }

    .MuiSlider-rail {
        opacity: 1;
        background-color: $lightGreyColor;
    }

    .MuiSlider-valueLabel {
        background-color: $themeColor;
        font-size: 0.75rem;
        border-radius: 5px;
        padding: 2px 5px;
    }

    .MuiSlider-mark,
    .MuiSlider-markActive {
        background-color: transparent;
        border-right: 1px solid $lightGreyColor;
        height: 20px;
        width: 0;
        top: 85%;
        opacity: 1;
    }

    .MuiSlider-markLabel {
        margin-top: 10px;
        font-size: 0.75rem
    }
}