@import '../../assets/scss/mixin.scss';


.MuiAutocomplete-paper {
    background: #F4F7FF;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}

.MuiAutocomplete-listbox {
    @include scroll-bar-style(60px);

    .MuiAutocomplete-option {
        text-transform: capitalize;
    }
}
