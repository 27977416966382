.profile-required-info-container {
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .profile-required-info-form {
        width: 600px;

        .modal-title {
            font-size: 1.25rem;
            font-weight: 600;
            color: #1A1E29;
        }

        .modal-subtitle {
            margin-bottom: 12px;
        }

        .modal-description {
            margin-bottom: 16px;
        }

        .input-description {
            padding-right: 6px;
            padding-left: 6px;
        }

        .placeholder-container {
            height: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .profile-details-card__body {
            height: 300px;
            overflow-y: scroll;
        }

        .skill-card-wrapper {
            flex-direction: column;
        }

        .button-wrapper {
            display: flex;
            justify-content: center;
        }
    }
}
