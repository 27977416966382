.strength-meter {
    position: relative;
    height: 3px;
    background: #ddd;
    margin: 7px 0;
    border-radius: 2px;
}

.strength-meter:before,
.strength-meter:after {
    content: "";
    height: inherit;
    background: transparent;
    display: block;
    border-color: #fff;
    border-style: solid;
    border-width: 0 6px 0;
    position: absolute;
    width: calc(20% + 6px);
    z-index: 10;
}

.strength-meter:before {
    left: calc(20% - 3px);
}

.strength-meter:after {
    right: calc(20% - 3px);
}

.strength-meter-fill {
    background: transparent;
    height: inherit;
    position: absolute;
    width: 0;
    border-radius: inherit;
    transition: width 0.5s ease-in-out, background 0.25s;
}

.strength-meter-fill[data-strength="0"] {
    width: 20%;
    background: darkred;
}

.strength-meter-fill[data-strength="1"] {
    width: 40%;
    background: orangered;
}

.strength-meter-fill[data-strength="2"] {
    width: 60%;
    background: orange;
}

.strength-meter-fill[data-strength="3"] {
    width: 80%;
    background: yellowgreen;
}

.strength-meter-fill[data-strength="4"] {
    width: 100%;
    background: green;
}

.strength-value {
    display: flex;
    justify-content: flex-end;
}