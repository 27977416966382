@import '../../../assets/scss/theme-variable.scss';

.RecruiterDashboard-section {
    width: 100%;

    .header {
        width: 100%;
        background-color: $whiteColor;
        padding: 12px 40px 0;
        border-bottom: 1px solid $lightGreyColor;

        .heading-div {
            display: flex;
            justify-content: space-between;
            padding: 12px 0;

            h3 {
                color: $textColor;
                font-size: 2.125rem;
                font-weight: 600;
                line-height: 2.75rem;
            }

            .add-new-job {
                font-size: 1rem;
                width: 124px;
                padding: 10px 15px;
            }
        }

        .jobs-tab-section {
            display: flex;
            margin-top: 30px;

            .job-type-tab {
                width: auto;
                text-align: center;
                margin-right: 30px;
                cursor: pointer;

                .text {
                    position: relative;
                    transition: all 0.2s;
                    font-weight: 400;
                    font-size: 1rem;
                    line-height: 1.5rem;
                    color: $greyColor;
                    height: 40px;
                    display: inline-block;

                    &::after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        border-radius: 10px 10px 0px 0px;
                        height: 5px;
                        width: 100%;
                        opacity: 0;
                        transition: all 0.2s;
                        background-color: $themeColor;
                    }

                    &.active {
                        color: $textColor;
                        font-weight: 600;

                        &::after {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    .jobs-tab-content {
        background-color: $offWhiteColor;
        padding: 30px 40px;
        width: 100%;

        .job-title-label {
            font-weight: 600;
            cursor: pointer;

            &:hover {
                color: $themeColor;
                text-decoration: underline;
            }
        }

        .job-actions {
            color: $greyColor;
            display: flex;
            gap: 18px;
            justify-content: center;
            width: 100%;
            font-size: 1.125rem;

            .action-edit,
            .action-delete {
                cursor: pointer;
            }
        }
    }
}
